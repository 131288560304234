import * as React from "react"
import { Helmet } from "react-helmet"
import SimpleReactLightbox from "simple-react-lightbox"
import useSiteMetadata from "../../hooks/use-site-metadata"
import layoutBg from "../../images/bg-top-new.jpg"
import ogImageDefault from "../../images/og-image.jpg"
import Footer from "../footer"

type LayoutProps = {
  className?: string
  title?: string
  description?: string
  ogType?: string
  ogImage?: string
  ogPrice?: string
  ogPriceCurrency?: string
  ogImageHeight?: string
  ogImageWidth?: string
  children: React.ReactNode
}

export default function Layout({
  children,
  title,
  description,
  ogImage,
  ogType = "website",
  ogImageWidth = "1200",
  ogImageHeight = "600",
  className = "",
}: LayoutProps) {
  const metadata = useSiteMetadata()

  return (
    <SimpleReactLightbox>
      <Helmet htmlAttributes={{ class: "scroll-smooth" }}>
        <meta charSet="utf-8" />
        <title>{title ? `${title} | ${metadata.title}` : metadata.title}</title>
        <meta
          name="description"
          content={description ?? metadata.description}
        />
        <meta property="og:image" content={ogImage ?? ogImageDefault} />
        <meta property="og:image:height" content={ogImageHeight} />
        <meta property="og:image:width" content={ogImageWidth} />
        <meta property="og:type" content={ogType} />
        <link rel="canonical" href={metadata.siteUrl} />
        <meta name="twitter:title" content={title ?? metadata.title} />
        <meta
          name="twitter:description"
          content={description ?? metadata.description}
        />
        <meta name="twitter:card" content="summary" />
        <script src="https://apps.elfsight.com/p/platform.js" defer />
      </Helmet>
      <div
        className={`layout ${className} bg-fixed bg-center bg-cover flex min-h-screen bg-no-repeat bg-black relative flex-col justify-between`}
        style={{ backgroundImage: `url(${layoutBg})` }}
      >
        <main className={"min-h-screen"}>{children}</main>
        <Footer />
        <div className="elfsight-app-1b460d8c-4605-4067-ae6c-5fae85b5d544" />
      </div>
    </SimpleReactLightbox>
  )
}
