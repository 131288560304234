import * as React from "react"
import Container from "../container"

export default function Footer() {
  return (
    <footer
      className={
        "font-cursive text-yellow text-sm flex bg-black items-center w-full p-6"
      }
    >
      <Container
        className={"justify-center items-center gap-4 flex-col sm:flex-row"}
      >
        <div
          className={
            "flex flex-col items-center text-center gap-2 sm:text-left sm:gap-2"
          }
        >
          <p>
            Copyright &copy; {new Date().getFullYear()} DAT HUONG Pty Ltd. All
            Rights Reserved.
          </p>
          <p>ABN: 91 659 123 921</p>
        </div>
      </Container>
    </footer>
  )
}
