import * as React from "react"
import { Link } from "gatsby"

interface NavBarLink {
  name: string
  href: string
}

export const NavBarLinks: NavBarLink[] = [
  {
    name: "Menu",
    href: "#menu",
  },
  {
    name: "Group Bookings",
    href: "#group-bookings",
  },
  {
    name: "Bar",
    href: "#bar",
  },
  {
    name: "Reservations",
    href: "#reservations",
  },
  {
    name: "Vouchers",
    href: "#vouchers",
  },
  {
    name: "Reviews",
    href: "/reviews",
  },
  {
    name: "Contact",
    href: "#contact",
  },
]

export default function NavBar() {
  const navBarClass = (path: string) => {
    const currentPath =
      typeof window !== "undefined" ? window.location.pathname : ""

    return path === currentPath ? "bg-primary px-2" : ""
  }

  return (
    <ul className={"gap-8 hidden lg:flex"}>
      {NavBarLinks.map((link, index) => {
        const linkClass = navBarClass(link.href)

        return (
          <li
            className={
              "uppercase transition-colors hover:text-yellow text-white text-xl"
            }
            key={`navbar-${index}`}
          >
            <Link className={linkClass} to={link.href}>
              {link.name}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}
